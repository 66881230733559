export const BASE_URL = "https://system.beanheaditsolutions.com/api/v1";
export const API = {
    affiliates: "/affiliates"
}
export const AFFILIATES = [
    {
        links: "#",
        label: "3 in 1 Foldable Stand [Shopee]"
    },
    {
        links: "#",
        label: "Laptop stand adjustable [Shopee]"
    }
]