import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Images
import LogoImage from '../assets/images/shopping_logo.png';
import { Grid } from '@mui/material';
import { AFFILIATES } from '../config';

const Copyright = (props) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      <Link color="inherit" href="#">
        BH - Product Cheap Finds
      </Link>
    </Typography>
  );
}

const theme = createTheme();

export default function Affiliates() {
    const getLinks = AFFILIATES;
    const gotToAffiliateLink = (link) => {
        window.location.href = link; 
    };

    return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
                <Avatar
                    alt="Remy Sharp"
                    src={LogoImage}
                    sx={{ width: 100, height: 100 }}
                />
                <Typography component="h1" variant="h5" mt={2}>
                    BH - Product Cheap Finds
                </Typography>
                <Grid container rowSpacing={2} mt={1}>
                    {
                        getLinks && getLinks.length > 0 ? getLinks.map((affil, index) => <Grid item xs={12} key={`${index}`}>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                onClick={() => gotToAffiliateLink(affil.links)} 
                                >
                            {affil.label}
                            </Button>
                        </Grid>) : null
                    }
                </Grid>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
        </ThemeProvider>
    );
}