import Affiliates from './affiliates';
import './App.css';

function App() {
  return (
    <Affiliates />
  );
}

export default App;
